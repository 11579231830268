import cn from 'classnames';
import React from 'react';
import type { FC, ReactNode } from 'react';

import s from './FormRow.module.scss';

type Props = {
  children?: ReactNode;
  className?: string;
};

const FormRow: FC<Props> = ({ children, className = '' }) => (
  <div className={cn(s.root, className)}>{children}</div>
);

export default FormRow;
